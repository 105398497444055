import React from 'react';
import TintashLogo from '../../assets/images/logo/tintash-logo-blue.svg';

import AppStore from '../../assets/images/art/appStore.svg';
import GooglePlay from '../../assets/images/art/googlePlay.svg';
import AdsTextBackground from '../../assets/images/art/adsTextBackground.svg';

import '../../assets/css/art/ads.css';

const AdsDetails = ({
  mainImage,
  mainTitle,
  publisherImg,
  textDetails,
  appStoreLink,
  googlePlayLink,
}) => (
  <div
    className="ads-details-conatiner"
    style={{backgroundImage: `url(${AdsTextBackground})`, backgroundSize: 'contain'}}
  >
    <div className="ads-art-wrapper">
      <img className="art-main-logo" src={TintashLogo} alt="tintash logo" />
      <div className="ads-title-section">
        <img className="ads-title-img" src={mainImage} alt="nail salon img" />
        <h3 className="ads-title-text">{mainTitle}</h3>
      </div>
      {publisherImg && (
        <div className="ads-publisher-section">
          <h4 className="ads-publisher-text">Publisher: </h4>

          <img className="ads-publisher-img" src={publisherImg} alt="lion studios img" />
        </div>
      )}
      <p className="art-ads-description">{textDetails}</p>
      <div className="ads-store-images">
        {appStoreLink && (
          <a href={appStoreLink} target="_blank" rel="noreferrer">
            <img className="ads-store-img" src={AppStore} alt="app store" />
          </a>
        )}
        {googlePlayLink && (
          <a href={googlePlayLink} target="_blank" rel="noreferrer">
            <img className="ads-store-img" src={GooglePlay} alt="google play" />
          </a>
        )}
      </div>
    </div>
  </div>
);
export default AdsDetails;
