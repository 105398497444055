import React from 'react';
import AdPage from '../../components/Art/AdPage';
// Nail Salon Ad
import Nail1 from '../../assets/images/art/Nail1.png';
import Nail2 from '../../assets/images/art/Nail2.png';
import Nail3 from '../../assets/images/art/Nail3.png';
import Nail4 from '../../assets/images/art/Nail4.png';
import Nail5 from '../../assets/images/art/Nail5.png';
import Nail6 from '../../assets/images/art/Nail6.png';
import NailSalonImg from '../../assets/images/art/nailSalonImg.svg';

// Harvester Ad
import Harvester1 from '../../assets/images/art/Harvester1.jpg';
import Harvester2 from '../../assets/images/art/Harvester2.jpg';
import Harvester3 from '../../assets/images/art/Harvester3.jpg';
import Harvester4 from '../../assets/images/art/Harvester4.jpg';
import Harvester5 from '../../assets/images/art/Harvester5.jpg';

// Face Painting Ad
import Face1 from '../../assets/images/art/Face1.jpg';
import Face2 from '../../assets/images/art/Face2.jpg';
import Face3 from '../../assets/images/art/Face3.jpg';
import Face4 from '../../assets/images/art/Face4.jpg';
import Face5 from '../../assets/images/art/Face5.jpg';
import Face6 from '../../assets/images/art/Face6.jpg';
import FacePainting from '../../assets/images/art/FacePainting1.png';

// Perfect Ad
import Perfect1 from '../../assets/images/art/Perfect1.jpg';
import Perfect2 from '../../assets/images/art/Perfect2.jpg';
import Perfect3 from '../../assets/images/art/Perfect3.jpg';
import Perfect4 from '../../assets/images/art/Perfect4.jpg';
import Perfect5 from '../../assets/images/art/Perfect5.jpg';
import Perfect6 from '../../assets/images/art/Perfect6.jpg';
import PerfectHeel from '../../assets/images/art/PerfectHeels5.png';

// Event Ad
import Event1 from '../../assets/images/art/Event1.jpg';
import Event2 from '../../assets/images/art/Event2.jpg';
import Event3 from '../../assets/images/art/Event3.jpg';
import Event4 from '../../assets/images/art/Event4.jpg';
import Event5 from '../../assets/images/art/Event5.jpg';
import Event6 from '../../assets/images/art/Event6.jpg';
import EventPlanner from '../../assets/images/art/EventPlanner.png';

// Food Ad
import Food1 from '../../assets/images/art/Food1.jpg';
import Food2 from '../../assets/images/art/Food2.jpg';
import Food3 from '../../assets/images/art/Food3.jpg';
import Food4 from '../../assets/images/art/Food4.jpg';
import Food5 from '../../assets/images/art/Food5.jpg';
import FoodCutting from '../../assets/images/art/FoodCutting.png';

// Tangle Ad
import Tangle1 from '../../assets/images/art/Tangle1.jpg';
import Tangle2 from '../../assets/images/art/Tangle2.jpg';
import Tangle3 from '../../assets/images/art/Tangle3.jpg';
import Tangle4 from '../../assets/images/art/Tangle4.jpg';
import Tangle5 from '../../assets/images/art/Tangle5.jpg';
import Tangle6 from '../../assets/images/art/Tangle6.jpg';
import TangleImg from '../../assets/images/art/Tangle.png';

// Sketch Ad
import Sketch1 from '../../assets/images/art/Sketch1.jpg';
import Sketch2 from '../../assets/images/art/Sketch2.jpg';
import Sketch3 from '../../assets/images/art/Sketch3.jpg';
import Sketch4 from '../../assets/images/art/Sketch4.jpg';
import Sketch5 from '../../assets/images/art/Sketch5.jpg';
import Sketch6 from '../../assets/images/art/Sketch6.jpg';
import Sketch7 from '../../assets/images/art/Sketch7.jpg';
import SketchGuess from '../../assets/images/art/SketchGuess.png';

import LionStudiosImg from '../../assets/images/art/lionStudios.svg';
import '../../assets/css/art/ads.css';

const AdsSection = () => {
  const nailVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188308/Nail_hwwell.mp4';
  const NailSlides = [
    {
      img: Nail1,
      vid: nailVideo,
    },
    {img: Nail2, vid: nailVideo},
    {img: Nail3, vid: nailVideo},
    {img: Nail4, vid: nailVideo},
    {img: Nail5, vid: nailVideo},
    {img: Nail6, vid: nailVideo},
  ];
  const harvestVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188465/HarvesterAd_ixzurm.mp4';
  const HarvesterSlides = [
    {
      img: Harvester1,
      vid: harvestVideo,
    },
    {img: Harvester2, vid: harvestVideo},
    {img: Harvester3, vid: harvestVideo},
    {img: Harvester4, vid: harvestVideo},
    {img: Harvester5, vid: harvestVideo},
  ];
  const faceVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188583/FacePainting_dj8gvo.mp4';
  const FacePaintingSlides = [
    {
      img: Face1,
      vid: faceVideo,
    },
    {img: Face2, vid: faceVideo},
    {img: Face3, vid: faceVideo},
    {img: Face4, vid: faceVideo},
    {img: Face5, vid: faceVideo},
    {img: Face6, vid: faceVideo},
  ];
  const perfectVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188687/Perfect_hsodax.mp4';
  const PerfectSlides = [
    {
      img: Perfect1,
      vid: perfectVideo,
    },
    {img: Perfect2, vid: perfectVideo},
    {img: Perfect3, vid: perfectVideo},
    {img: Perfect4, vid: perfectVideo},
    {img: Perfect5, vid: perfectVideo},
    {img: Perfect6, vid: perfectVideo},
  ];
  const eventVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716187759/Event_m7yr0w.mp4';
  const EventSlides = [
    {
      img: Event1,
      vid: eventVideo,
    },
    {img: Event2, vid: eventVideo},
    {img: Event3, vid: eventVideo},
    {img: Event4, vid: eventVideo},
    {img: Event5, vid: eventVideo},
    {img: Event6, vid: eventVideo},
  ];
  const foodVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716189076/Food_uhkb0r.mp4';
  const FoodSlides = [
    {
      img: Food1,
      vid: foodVideo,
    },
    {img: Food2, vid: foodVideo},
    {img: Food3, vid: foodVideo},
    {img: Food4, vid: foodVideo},
    {img: Food5, vid: foodVideo},
  ];
  const tangleVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188800/Tangle_tvte7v.mp4';
  const TangleSlides = [
    {
      img: Tangle1,
      vid: tangleVideo,
    },
    {img: Tangle2, vid: tangleVideo},
    {img: Tangle3, vid: tangleVideo},
    {img: Tangle4, vid: tangleVideo},
    {img: Tangle5, vid: tangleVideo},
    {img: Tangle6, vid: tangleVideo},
  ];
  const sketchVideo =
    'https://res.cloudinary.com/tweedl-dev/video/upload/v1716188883/Sketch_xa9uki.mp4';
  const SketchSlides = [
    {
      img: Sketch1,
      vid: sketchVideo,
    },
    {img: Sketch2, vid: sketchVideo},
    {img: Sketch3, vid: sketchVideo},
    {img: Sketch4, vid: sketchVideo},
    {img: Sketch5, vid: sketchVideo},
    {img: Sketch6, vid: sketchVideo},
    {img: Sketch7, vid: sketchVideo},
  ];

  return (
    <div className="art-ads-main-page">
      <AdPage
        slidesData={NailSlides}
        miroLink="https://miro.com/app/board/uXjVNklezyY=/"
        mainImage={NailSalonImg}
        mainTitle="NAIL SALON 3D"
        publisherImg={LionStudiosImg}
        textDetails="#4 in Top Free Games, USA AppStore with 50 million worldwide downloads. Nail salon game
        gives you the chance to become perfect your manicure madness and become the best Nail Salon."
        appStoreLink="https://apps.apple.com/us/app/nail-salon-3d/id1523081624"
        googlePlayLink="https://play.google.com/store/apps/details?id=com.tintash.nailsalon&hl=en&gl=US"
      />
      <AdPage
        slidesData={FoodSlides}
        miroLink="https://miro.com/app/board/uXjVKf03RyE=/"
        mainImage={FoodCutting}
        mainTitle="Food Cutting"
        publisherImg={LionStudiosImg}
        textDetails="
        Cut the food in the right proportions to make the customers happy and increase your sales! It must sound simple, but don’t let it trick you; it can be quite hard at times! Using a swipe or knife, cut the pieces of food according to the given weight, and in case you’re wrong, do your absolute best to correct your mistake and not make the impatient customers mad!
        "
        appStoreLink="https://apps.apple.com/us/app/food-cutting-chopping-game/id1564468425"
        googlePlayLink="https://play.google.com/store/apps/details?id=com.relaxgames.foodcutting&hl=en&gl=US"
      />
      <AdPage
        slidesData={TangleSlides}
        miroLink="https://miro.com/app/board/uXjVKf-ytYw=/"
        mainImage={TangleImg}
        mainTitle="Tangle Paint"
        publisherImg=""
        textDetails="Untangle yarn threads and crochet beautiful figures in this ultimate puzzle game.

        Sort the threads in the correct order and untangle them all on time. You may have limited moves to play so be ready for that challenge.
        "
        appStoreLink=""
        googlePlayLink=""
      />
      <AdPage
        slidesData={EventSlides}
        miroLink="https://miro.com/app/board/uXjVKc3Jz6M=/"
        mainImage={EventPlanner}
        mainTitle="Event Planner"
        publisherImg=""
        textDetails="Welcome to Event Planner, the super fun game where you get to plan awesome parties! From weddings to birthdays, you're in charge of making each event amazing. Just swipe, tap, and move things around to create the perfect party. With easy controls and cool graphics, it's the best game to relax and have fun with. Get ready to be the party pro in this addictive game!"
        appStoreLink=""
        googlePlayLink=""
      />
      <AdPage
        slidesData={HarvesterSlides}
        miroLink="https://miro.com/app/board/uXjVKf7A7cw=/"
        mainImage={NailSalonImg}
        mainTitle="Harvester Rush"
        publisherImg=""
        textDetails="Check out Harvester Rush, It's the game that's all about relaxing and harvesting crops. As you move through the levels, you'll unlock a variety of crops that get more valuable each time. With easy one-tap controls and charming graphics, it's a fun and engaging way to spend your time. Dive in and start harvesting today—see how much you can grow your profits!"
        appStoreLink=""
        googlePlayLink=""
      />
      <AdPage
        slidesData={FacePaintingSlides}
        miroLink="https://miro.com/app/board/uXjVKdDOuaM=/"
        mainImage={FacePainting}
        mainTitle="Face Painting"
        publisherImg=""
        textDetails="Discover Face Painting 3D, the ultimate fun game where you can unleash your creativity with masks, face paints, and makeup. Experiment with different colors and designs to create stunning looks. The game features easy-to-use controls and vibrant graphics, making it fun and engaging for all ages. Dive into Face Painting 3D and let your artistic skills shine! Are you ready to create the most amazing face art ever?"
        appStoreLink=""
        googlePlayLink=""
      />
      <AdPage
        slidesData={PerfectSlides}
        miroLink="https://miro.com/app/board/uXjVKedoamo=/"
        mainImage={PerfectHeel}
        mainTitle="Perfect Heels"
        publisherImg=""
        textDetails="Step into the world of Perfect Heels, the ultimate game where you can strut your stuff in the most fabulous heels. Customize your shoes with different styles, colors, and accessories to create the perfect look. With simple controls and eye-catching graphics, this game is fun and addictive for fashion lovers of all ages. Dive into Perfect Heels and showcase your style! Are you ready to design the most stunning heels ever?"
        appStoreLink=""
        googlePlayLink=""
      />

      <AdPage
        slidesData={SketchSlides}
        miroLink="https://miro.com/app/board/uXjVKe9R6CA=/"
        mainImage={SketchGuess}
        mainTitle="Sketch Guess"
        publisherImg=""
        textDetails="Enter the world of Sketch Guess, the best puzzle game where your imagination is your best tool. View a sketch and put your guessing skills to the test as you decipher the word puzzle it represents. With a diverse range of sketches and intuitive gameplay, every round is filled with excitement. Join Sketch Guess and see if you can decode the sketches in time! Are you up for the challenge of guessing the hidden words?"
        appStoreLink=""
        googlePlayLink=""
      />
    </div>
  );
};

export default AdsSection;
