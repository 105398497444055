import React from 'react';
import AdsDetails from './AdsDetails';
import AdsCarousel from './adsCarousel';
import artBackgroundImg from '../../assets/images/art/adBackground.svg';

const adPage = ({
  slidesData,
  miroLink,
  mainImage,
  mainTitle,
  publisherImg,
  textDetails,
  appStoreLink,
  googlePlayLink,
}) => (
  <div
    className="art-ads-section"
    style={{backgroundImage: `url(${artBackgroundImg})`, backgroundSize: 'cover'}}
  >
    <AdsDetails
      mainImage={mainImage}
      mainTitle={mainTitle}
      publisherImg={publisherImg}
      textDetails={textDetails}
      appStoreLink={appStoreLink}
      googlePlayLink={googlePlayLink}
    />
    <AdsCarousel slidesData={slidesData} miroLink={miroLink} />
  </div>
);
export default adPage;
